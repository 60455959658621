<template>
  <div class="logotypes-widget">
    <ImageWithAreaMap
      :id="`desktop_${widget.id}`"
      :class="{
        'md-up': widget.image_mobile && !widget.image_tablet,
        'lg-up': widget.image_tablet
      }"
      :image="widget.image"
      :map-areas="widget.map_areas"
    />
    <ImageWithAreaMap
      v-if="widget.image_tablet"
      :id="`tablet_${widget.id}`"
      :class="{ 'md-up': widget.image_mobile }"
      class="lg-down"
      :image="widget.image_tablet"
      :map-areas="widget.map_areas_tablet"
    />
    <ImageWithAreaMap
      v-if="widget.image_mobile"
      :id="`mobile_${widget.id}`"
      class="md-down"
      :image="widget.image_mobile"
      :map-areas="widget.map_areas_mobile"
    />
  </div>
</template>

<script lang="ts" setup>
import { PublicImageWithMultipleLinksWidget } from '@/service/__generated-api'
import { Widget } from '@/types/utils'

const { t } = useI18n()

defineProps<Widget<PublicImageWithMultipleLinksWidget>>()
</script>

<style scoped lang="scss">
.logotypes-widget {
  background-color: $white;
}
</style>
